import React, { useEffect, useState } from 'react';
import useWindowSize from '../../../hooks/useWindowSize';
import Menu from '../Menu/Menu';
import Sidebar from '../Sidebar/Sidebar';
import './Header.scss';
import logo from '../../../assets/images/vlad-morosanu-logo-purple.svg';

const Header = ({ isScrolled, handleClickAgenda }) => {
  const [isMenuSidebar, setIsMenuSidebar] = useState(null);
  const size = useWindowSize();

  useEffect(() => {
    if (size.width !== undefined && size.width < 1000) {
      setIsMenuSidebar(true);
    } else if (size.width !== undefined && size.width >= 1000) {
      setIsMenuSidebar(false);
    }
  }, [size]);
  return (
    <>
      <header className={isScrolled ? 'header down' : 'header'}>
        <div onClick={() => handleClickAgenda('presentation')} className='logo'>
          <img className='logo-icon' src={logo} alt="logo" />
        </div>
        {isMenuSidebar != null ? (
          isMenuSidebar ? (
            <Sidebar handleClickAgenda={handleClickAgenda} />
          ) : (
            <Menu handleClickAgenda={handleClickAgenda} />
          )
        ) : (
          ''
        )}
      </header>
    </>
  );
};
export default Header;
