import React, { useEffect } from 'react';
import { usePrevious } from '../../../../hooks/usePrevious';
import TimelineItem from '../TimelineItem/TimelineItem';
import './Education.scss';

const Education = ({ isVisible, handleAgenda }) => {
  const prevIsActive = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevIsActive !== isVisible) {
      handleAgenda('education');
    }
  }, [isVisible, handleAgenda, prevIsActive]);
  return (
    <div id='education' className='section education-section'>
      <div className='history'>
        <h1 className='title'>Education</h1>
        <TimelineItem
          startDate={'10/2019'}
          endDate={'07/2021'}
          title={
            'Master\'s degree in Computational Optimization "Alexandru Ioan Cuza" University'
          }
          location={'Faculty of Computer Science, Iasi'}
          color={'orange'}
          description={`Worked with statistics and artificial intelligence algorithms and gained decent knowledge of machine learning and neural networks algorithms concepts`}
        />
        <TimelineItem
          startDate={'10/2016'}
          endDate={'07/2019'}
          title={
            'Bachelor\'s degree in Computer Science at Alexandru Ioan Cuza" University'
          }
          location={'Faculty of Computer Science, Iasi'}
          color={'orange'}
          description={
            'Learnt OOP principles and built web applications using Javascript, .NET and NodeJS'
          }
        />
        <TimelineItem
          startDate={'09/2012'}
          endDate={'06/2016'}
          title={'Baccalaureate Diploma, Mathematics-Computer Science'}
          location={'"Mihai Eminescu" High school, Suceava'}
          color={'orange'}
          lastItem={true}
        />
      </div>
      <div className='details'>
        <div className='title text-black'>Description</div>
        <div className='description text-gray'>
          During my master’s degree, I developed applications based on cloud
          architecture such as Google Cloud or Amazon Cloud Services. I also
          implemented neural networks principles and image processing techniques
          using Python language and Keras framework.
        </div>
        <div className='description text-gray margin-top-50'>
          Aside from the usual university projects, I was also involved in the
          writing of a collaborative research paper, entitled{' '}
          <b>Automatic Real-Time Road Crack Identification System</b>. The
          purpose of this article was to present our methods (such as
          Convolutional Neural Network, U-Net and a Local Binary Pattern),
          experiments and results for detecting cracks on surfaces like streets
          and sidewalks.
        </div>
      </div>
    </div>
  );
};

export default Education;
