import React, { useEffect, useRef, useState } from 'react';

import presentation from '../../../../assets/images/icons/presentation.png';
import experience from '../../../../assets/images/icons/experience.png';
import skills from '../../../../assets/images/icons/skills.png';
import education from '../../../../assets/images/icons/education.png';
import projects from '../../../../assets/images/icons/projects.png';
import contact from '../../../../assets/images/icons/contact.png';
import presentationColor from '../../../../assets/images/icons/presentation-color.png';
import experienceColor from '../../../../assets/images/icons/experience-color.png';
import educationColor from '../../../../assets/images/icons/education-color.png';
import projectsColor from '../../../../assets/images/icons/projects-color.png';
import contactColor from '../../../../assets/images/icons/contact-color.png';
import skillsColor from '../../../../assets/images/icons/skills-color.png';
import doubleLeftIcon from '../../../../assets/images/icons/double-left.png';
import doubleRightIcon from '../../../../assets/images/icons/double-right.png';
import agendaIcon from '../../../../assets/images/icons/agenda.png';
import closeIcon from '../../../../assets/images/icons/cancel.png';

import './HomeAgenda.scss';
import useWindowSize from '../../../../hooks/useWindowSize';
import { useClickOutside } from '../../../../hooks/useClickOutside';

const HomeAgenda = ({ section, handleClickAgenda }) => {
  const [isAgendaExpanded, setIsAgendaExpanded] = useState(false);
  const [isAgendaVisible, setIsAgendaVisible] = useState(false);
  const size = useWindowSize();

  const onClickToggleAgenda = () => {
    if (isAgendaExpanded && size.width > 1100 && size.width < 1700) {
      toggleExpandAgenda();
    }
    if (isAgendaVisible && size.width < 1100) {
      toggleVisibilityAgenda();
    }
  };
  const toggleExpandAgenda = () => {
    setIsAgendaExpanded(!isAgendaExpanded);
  };

  const toggleVisibilityAgenda = () => {
    setIsAgendaVisible(!isAgendaVisible);
  };

  const homeAgendaRef = useRef();
  useClickOutside(homeAgendaRef, onClickToggleAgenda);
  useEffect(() => {
    if (size.width !== undefined && size.width >= 1700) {
      setIsAgendaExpanded(true);
      setIsAgendaVisible(true);
    }
    if (size.width !== undefined && size.width > 1100 && size.width < 1700) {
      setIsAgendaExpanded(false);
      setIsAgendaVisible(true);
    }
    if (size.width !== undefined && size.width <= 1100) {
      setIsAgendaExpanded(false);
      setIsAgendaVisible(false);
    }
  }, [size.width]);

  return (
    <div ref={homeAgendaRef} className='home-agenda-section'>
      {isAgendaVisible ? (
        <div
          className={
            isAgendaExpanded
              ? 'home-agenda agenda-extended'
              : 'home-agenda agenda-collapsed'
          }>
          <div className='close'>
            {size.width < 1100 && !isAgendaExpanded ? (
              <img
                onClick={() => toggleVisibilityAgenda()}
                className='close-icon'
                src={closeIcon}
                alt='presentation'
              />
            ) : (
              ''
            )}
          </div>

          <ul className='agenda'>
            <li
              onClick={() => {
                handleClickAgenda('presentation');
                onClickToggleAgenda();
              }}
              className={section === 'presentation' ? 'item selected' : 'item'}>
              <img
                className='item-icon'
                src={
                  section === 'presentation' ? presentationColor : presentation
                }
                alt='presentation'
              />
              {isAgendaExpanded ? 'Presentation' : ''}
            </li>
            <li
              onClick={() => {
                handleClickAgenda('experience', -125);
                onClickToggleAgenda();
              }}
              className={section === 'experience' ? 'item selected' : 'item'}>
              <img
                className='item-icon'
                src={section === 'experience' ? experienceColor : experience}
                alt='presentation'
              />
              {isAgendaExpanded ? 'Experience' : ''}
            </li>
            <li
              onClick={() => {
                handleClickAgenda('skills', -125);
                onClickToggleAgenda();
              }}
              className={section === 'skills' ? 'item selected' : 'item'}>
              <img
                className='item-icon'
                src={section === 'skills' ? skillsColor : skills}
                alt='presentation'
              />
              {isAgendaExpanded ? 'Skills' : ''}
            </li>
            <li
              onClick={() => {
                handleClickAgenda('education', -125);
                onClickToggleAgenda();
              }}
              className={section === 'education' ? 'item selected' : 'item'}>
              {' '}
              <img
                className='item-icon'
                src={section === 'education' ? educationColor : education}
                alt='presentation'
              />
              {isAgendaExpanded ? 'Education' : ''}
            </li>
            <li
              onClick={() => {
                handleClickAgenda('personalProjects', -125);
                onClickToggleAgenda();
              }}
              className={
                section === 'personalProjects' ? 'item selected' : 'item'
              }>
              {' '}
              <img
                className='item-icon'
                src={section === 'personalProjects' ? projectsColor : projects}
                alt='presentation'
              />
              {isAgendaExpanded ? 'Projects' : ''}
            </li>
            <li
              onClick={() => {
                handleClickAgenda('contact');
                onClickToggleAgenda();
              }}
              className={section === 'contact' ? 'item selected' : 'item'}>
              <img
                className='item-icon'
                src={section === 'contact' ? contactColor : contact}
                alt='presentation'
              />
              {isAgendaExpanded ? 'Contact' : ''}
            </li>
          </ul>
          <div className='expand'>
            {isAgendaExpanded ? (
              <img
                className='expand-icon'
                onClick={toggleExpandAgenda}
                src={doubleLeftIcon}
                alt='close menu'
              />
            ) : (
              <img
                className='expand-icon'
                onClick={toggleExpandAgenda}
                src={doubleRightIcon}
                alt='open menu'
              />
            )}
          </div>
        </div>
      ) : (
        <div className='agenda-toggle-section'>
          <img
            className='agenda-icon'
            onClick={() => toggleVisibilityAgenda()}
            src={agendaIcon}
            alt='presentation'
          />
        </div>
      )}
    </div>
  );
};

export default HomeAgenda;
