import React from 'react';

import './ProjectCard.scss';
import rightIcon from '../../../../assets/images/icons/right.png';

const ProjectCard = ({ title, description, link }) => {
  return (
    <div className='card-section'>
      <h1 className='card-title'>{title}</h1>
      <div className='card-description'>{description}</div>
      <a className='more' href={link}>
        View Project
        <img
          className={'next-button'}
          src={rightIcon}
          alt='next click button'
        />
      </a>
    </div>
  );
};

export default ProjectCard;
