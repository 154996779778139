import React, { useEffect, useRef } from 'react';

import './PersonalProjects.scss';
import ProjectCard from '../ProjectCard/ProjectCard';

import leftIcon from '../../../../assets/images/icons/left.png';
import rightIcon from '../../../../assets/images/icons/right.png';
import ProjectExtendedCard from '../ProjectExtendedCard/ProjectExtendedCard';
import projectHealthyFood from '../../../../assets/images/healthyfood-ads.png';
import projectImage3 from '../../../../assets/images/eventup-home.png';
import { usePrevious } from '../../../../hooks/usePrevious';

const PersonalProjects = ({ isVisible, handleAgenda }) => {
  const prevIsActive = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevIsActive !== isVisible) {
      handleAgenda('personalProjects');
    }
  }, [isVisible, handleAgenda, prevIsActive]);

  const projectContainer = useRef(null);
  const handleBackProject = () => {
    projectContainer.current.scrollLeft -= 280;
  };

  const handleNextProject = () => {
    projectContainer.current.scrollLeft += 280;
  };
  return (
    <div id='personalProjects' className='section projects-section'>
      <div className='projects-content'>
        <h1 className='title'>Personal projects</h1>
        <div className='description'>
          In my spare time, I like to learn about new technologies and concepts
          in terms of web development. I have good experience in using
          Domain-Driven Design and Onion Architecture for modeling a project's
          functionality. The main technologies I used in my projects are React,
          Angular, .Net Core, SignalR. When working on UI/UX design, I prefer
          using Adobe InVision, a great tool for creating prototypes. Some of my
          main projects worth mentioning are:
        </div>
        <div className='project-illustration-section width-50'>
          {/* <img
            className='project-illustration'
            src={projectsIllustration}
            alt='projects user pc learning development portfolio'
          /> */}
        </div>
      </div>

      <div className='first-projects margin-top-50'>
        <ProjectExtendedCard
          imageSrc={projectHealthyFood}
          title='HealthyFood - find your local producers'
          description='An app where you can search announcements for local food, based on your location, talk with local growers and see range and quality of products or find producers who may be able to deliver to your area'
          link='https://github.com/vladmorosanu97/licenta'
        />
        <ProjectExtendedCard
          buttonColor='orange-background'
          imageSrc={projectImage3}
          title='EventUP - discover upcoming events based on your location'
          description='Discover upcoming events near you and stay up to date with the latest events such as concerts, festivals, conferences. You can view the presentation page of the event along with the distance between two locations'
          link='https://github.com/vladmorosanu97/eventup'
        />
        {/* <ProjectExtendedCard
          buttonColor='red-background'
          imageSrc={reactCalculatorPng}
          title='React calculator'
          description='A responsive application to calculate your finance directly from the browser'
        /> */}
      </div>

      <div className='carousel margin-top-100'>
        <img
          onClick={handleBackProject}
          className='back-button margin-right-20'
          src={leftIcon}
          alt='back click previous'
        />
        <div ref={projectContainer} className='cards padding-bottom-50'>
          <ProjectCard
            title='React Todo'
            description='A React app where you can manage tasks. The interface was created using Semantic UI library'
            link='https://github.com/vladmorosanu97/react-todone'
          />
          <ProjectCard
            title='React Calculator'
            description='An online calculator which performs arithmetic calculations. The design is responsive and can be accessed from any device'
            link='https://github.com/vladmorosanu97/react-calculator'
          />
          <ProjectCard
            title='Happy Reading'
            description='An e-commerce application useful to manage your stock of books. The users can search for products which can be added to their cart'
            link='https://github.com/vladmorosanu97/happy-reading'
          />
        </div>

        <img
          onClick={handleNextProject}
          className='next-button'
          src={rightIcon}
          alt='next click button'
        />
      </div>
    </div>
  );
};

export default PersonalProjects;
