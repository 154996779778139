import React, { useEffect, useState } from 'react';

import presentationIllustration from '../../../../assets/images/illustrations/presentation-illustration.svg';
import down from '../../../../assets/images/icons/down.png';
import downGray from '../../../../assets/images/icons/down-gray.png';
import photo from '../../../../assets/images/me.png';

import './Presentation.scss';
import '../../../../assets/styles/Buttons.scss';
import '../../../../assets/styles/Margins.scss';
import '../../../../assets/styles/FlexBox.scss';
import { usePrevious } from '../../../../hooks/usePrevious';

const Presentation = ({ isVisible, handleAgenda, handleClickAgenda }) => {
  const [iconDownSrc, setIconDownSrc] = useState(downGray);
  const prevIsActive = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevIsActive !== isVisible) {
      handleAgenda('presentation');
    }
  }, [isVisible, handleAgenda, prevIsActive]);

  return (
    <div id='presentation' className='section presentation-section'>
      <div className='display-flex'>
        <div className='presentation'>
          <div className='me'>
            <div className='user-section'>
              <img src={photo} alt='user' />
            </div>

            <div className='name-section'>
              <div className='name'>Vlad Morosanu</div>
              <div className='position'>Full Stack Developer</div>
            </div>
          </div>
          <div className='title'>Turn your ideas into reality</div>
          <div className='sub-title'>
            Howdy! Happy to have you here. My name is Vlad and I am a Full Stack
            developer, who also happens to be passionate about finance and
            psychology. If you're wondering "What is this place?" (which I hope
            you do), I would best describe it as a platform where I present some
            of my personal projects.
          </div>
          <div className='sub-title  margin-top-50'>
            Do you have an interesting idea?
          </div>
          <div
            onClick={() => handleClickAgenda('contact')}
            className='button button-large margin-top-20'>
            Let's collaborate
          </div>
        </div>
        <div className='image-section'>
          <img
            className='image'
            src={presentationIllustration}
            alt='table desk'
          />
        </div>
      </div>

      <div
        className='read-more'
        onMouseOver={() => setIconDownSrc(down)}
        onMouseOut={() => setIconDownSrc(downGray)}
        onClick={() => handleClickAgenda('experience', -140)}>
        <div className='text'>Read more</div>
        <img className='image' src={iconDownSrc} alt='more down' />
      </div>
      {/*<div id="quote-presentation" className='display-flex'>
        <div className='presentation quote-presentation'>
          <div className='title'>Do You Really Have to Fail to Succeed?</div>
          <div className='sub-title'>
            All your action are influenced by your habits. Let give your an example, you love reading books and this is a habit. It's likely to increase your knowledge about a subject and, of course, this will increase changes to success.
            If you failed and don't take a leason and hanve your habits, it's most probabbly to fail again.
            My motto is Fail is not an option.
            And it helps me to focus to create good quality software products, keeping in mind that there is only one direction, only to success.
          </div>
        </div>
        <div className='quote-section'>
          <div className='quote'>Though you should not fear failure, you should do your very best to avoid it</div>
          <div className='quote-author margin-top-20'>Conan O'Brien</div>
        </div>
      </div> */}
    </div>
  );
};

export default Presentation;
