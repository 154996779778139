import React, { useRef, useState } from 'react';

import './Sidebar.scss';
import menuIcon from '../../../assets/images/icons/menu.png';
import menuCloseIcon from '../../../assets/images/icons/cancel.png';
import { useClickOutside } from '../../../hooks/useClickOutside';
import ReactTooltip from 'react-tooltip';

const Sidebar = ({ handleClickAgenda }) => {
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);
  const sidebarRef = useRef();

  const toggleSidebar = () => {
    if (sidebar) {
      setSidebar(!sidebar);
    }
  };
  useClickOutside(sidebarRef, toggleSidebar);
  return (
    <>
      <div ref={sidebarRef}>
        <div className='button-section'>
          {sidebar ? (
            <img
              onClick={() => showSidebar()}
              src={menuCloseIcon}
              className='menu-icon close-icon'
              alt='menu icon'
            />
          ) : (
            <img
              onClick={() => showSidebar()}
              src={menuIcon}
              className='menu-icon'
              alt='menu icon'
            />
          )}
        </div>
        <nav className={sidebar ? 'sidebar active' : 'sidebar'}>
          <ul className='menu-items'>
            <li
              className='item selected'
              onClick={() => {
                showSidebar();
                handleClickAgenda('presentation');
              }}>
              Home
            </li>
            <li data-tip data-for='in-progress' className='item disabled'>
              Projects
            </li>
            <li data-tip data-for='in-progress' className='item disabled'>
              Blog
            </li>
            <li data-tip data-for='in-progress' className='item disabled'>
              Services
            </li>
            {/* <li data-tip data-for='in-progress' className='item disabled'>
              Contact
            </li> */}
            <ReactTooltip
              id='in-progress'
              type='warning'
              effect='solid'
              place='bottom'>
              <span>Under construction</span>
            </ReactTooltip>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
