import React, { useEffect } from 'react';

import frontIcon from '../../../../assets/images/icons/frontend.png';
import backendIcon from '../../../../assets/images/icons/backend.png';
import webDesignIcon from '../../../../assets/images/icons/web-design.png';
import skillsIllustration from '../../../../assets/images/illustrations/skills-illustration.svg';
import './Skills.scss';
import { usePrevious } from '../../../../hooks/usePrevious';
import javascriptSkill from '../../../../assets/images/skills/javascript_128x128.png';
import cssSkill from '../../../../assets/images/skills/css_128x128.png';
import htmlSkill from '../../../../assets/images/skills/html5_128x128.png';
import typescriptSkill from '../../../../assets/images/skills/typescript_128x128.png';
import angularSkill from '../../../../assets/images/skills/angular_128x128.png';
import sassSkill from '../../../../assets/images/skills/sass_128x128.png';
import jquerySkill from '../../../../assets/images/skills/jquery_128x128.png';
import csharpSkill from '../../../../assets/images/skills/csharp_128x128.png';
import netFrameworkSkill from '../../../../assets/images/skills/net-framework_128x128.png';
import netSkill from '../../../../assets/images/skills/net-6_128x128.png';
import entityFrameworkSkill from '../../../../assets/images/skills/entity-framework_128x128.png';
import sqlSkill from '../../../../assets/images/skills/sql_128x128.png';
import azureSkill from '../../../../assets/images/skills/azure_128x128.png';
import nodejsSkill from '../../../../assets/images/skills/nodejs_128x128.png';
import inVisionSkill from '../../../../assets/images/skills/invision_128x128.png';
import adobeXdSkill from '../../../../assets/images/skills/adobe-xd_128x128.png';

const Skills = ({ isVisible, handleAgenda }) => {
  const prevIsActive = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevIsActive !== isVisible) {
      handleAgenda('skills');
    }
  }, [isVisible, handleAgenda, prevIsActive]);
  return (
    <div id='skills' className='section skills-section'>
      <div className='skills'>
        <div className='title'>Skills</div>
        <div className='category margin-top-50'>
          <img className='icon' src={frontIcon} alt='Frontend' />
          Frontend
        </div>
        <div className='skills-list margin-top-20'>
          <div className='skill'>
            <img
              src={javascriptSkill}
              className='skill-logo'
              alt='JavaScript/ES7'
            />
            <div className='skill-text'>JavaScript/ES7</div>
          </div>
          <div className='skill'>
            <img src={cssSkill} className='skill-logo' alt='CSS3' />
            <div className='skill-text'>CSS3</div>
          </div>
          <div className='skill'>
            <img src={htmlSkill} className='skill-logo' alt='HTML5' />
            <div className='skill-text'>HTML5</div>
          </div>
          <div className='skill'>
            <img
              src={typescriptSkill}
              className='skill-logo'
              alt='Typescript'
            />
            <div className='skill-text'>Typescript</div>
          </div>
          <div className='skill'>
            <img src={angularSkill} className='skill-logo' alt='Angular' />
            <div className='skill-text'>Angular</div>
          </div>
          <div className='skill'>
            <img src={sassSkill} className='skill-logo' alt='SASS/SCSS/LESS' />
            <div className='skill-text'>SASS/SCSS/LESS</div>
          </div>
          <div className='skill'>
            <img src={jquerySkill} className='skill-logo' alt='jQuery' />
            <div className='skill-text'>jQuery</div>
          </div>
        </div>
        <div className='category margin-top-50'>
          <img className='icon' src={backendIcon} alt='Backend' />
          Backend
        </div>
        <div className='skills-list margin-top-20'>
          <div className='skill'>
            <img src={csharpSkill} className='skill-logo' alt='C#' />
            <div className='skill-text'>C#</div>
          </div>
          <div className='skill'>
            <img
              src={netFrameworkSkill}
              className='skill-logo'
              alt='NET Framework'
            />
            <div className='skill-text'>.NET Framework</div>
          </div>
          <div className='skill'>
            <img src={netSkill} className='skill-logo' alt='NET 6' />
            <div className='skill-text'>.NET 6</div>
          </div>
          <div className='skill'>
            <img src={sqlSkill} className='skill-logo' alt='SQL Server' />
            <div className='skill-text'>SQL Server</div>
          </div>
          <div className='skill'>
            <img
              src={entityFrameworkSkill}
              className='skill-logo'
              alt='Entity Framework'
            />
            <div className='skill-text'>Entity Framework</div>
          </div>

          <div className='skill'>
            <img
              src={azureSkill}
              className='skill-logo'
              alt='Microsoft Azure'
            />
            <div className='skill-text'>Microsoft Azure</div>
          </div>
          <div className='skill'>
            <img src={nodejsSkill} className='skill-logo' alt='NodeJS' />
            <div className='skill-text'>NodeJS</div>
          </div>
        </div>

        <div className='category margin-top-50'>
          <img className='icon' src={webDesignIcon} alt='Web design' />
          Web design
        </div>
        <div className='skills-list margin-top-20'>
          <div className='skill'>
            <img
              src={inVisionSkill}
              className='skill-logo'
              alt='Adobe InVision'
            />
            <div className='skill-text'>Adobe InVision</div>
          </div>
          <div className='skill'>
            <img src={adobeXdSkill} className='skill-logo' alt='Adobe XD' />
            <div className='skill-text'>Adobe XD</div>
          </div>
        </div>
      </div>
      <div className='skills-image'>
        <img className='image' src={skillsIllustration} alt='skills' />
      </div>
    </div>
  );
};

export default Skills;
