import React, { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';
import './App.css';
import Home from './components/Home/Home';
import Header from './components/shared/Header/Header';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isNavigationRegistered, setIsNavigationRegistered] = useState(false);

  function handleNavigation(e) {
    const window = e.currentTarget;
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  const handleClickAgenda = (item, offset) => {
    scroller.scrollTo(item, {
      duration: 400,
      delay: 50,
      smooth: true,
      offset: offset !== undefined ? offset : -100,
    });
  };

  useEffect(() => {
    // Update the document title using the browser API
    console.log('mount');
    window.addEventListener('scroll', handleNavigation, true);
    if (isNavigationRegistered === true) {
      window.removeEventListener('scroll', handleNavigation, true);
    }
    setIsNavigationRegistered(true);
  }, [isNavigationRegistered]);

  return (
    <div className='App'>
      <Header isScrolled={isScrolled} handleClickAgenda={handleClickAgenda} />
      <Home
        handleNavigation={handleNavigation}
        handleClickAgenda={handleClickAgenda}
      />
    </div>
  );
}

export default App;
