import React from 'react';

import './ProjectExtendedCard.scss';

const ProjectExtendedCard = ({
  title,
  description,
  buttonColor,
  imageSrc,
  link,
}) => {
  return (
    <div className='project-extended'>
      <div className='info'>
        <div className='project-title'>{title}</div>
        <div className='project-description'>{description}</div>
        <a
          href={link}
          className={
            'button button-large button-text-center margin-top-30 view-project ' +
            buttonColor
          }>
          View project
        </a>
      </div>
      <div className='project-image'>
        <img className='image' src={imageSrc} alt='img' />
      </div>
    </div>
  );
};

export default ProjectExtendedCard;
