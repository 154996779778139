import React from 'react';

import './TimelineItem.scss';
import locationIconPurple from '../../../../assets/images/icons/pin-color.png';
import locationIconOrange from '../../../../assets/images/icons/pin-orange.png';

const TimelineItem = (props) => {
  return (
    <div
      className={
        props.color === 'blue' ? 'timeline-item blue' : 'timeline-item orange'
      }>
      <div className='years'>
        {props.startDate} - {props.endDate}
      </div>
      <div className='timeline'>
        <div className='display-flex content-center align-center'>
          <span className='dot'></span>
          <span className='small-dot'></span>
        </div>
        {props.lastItem ? (
          <span className='line visible-only-mobile'></span>
        ) : (
          <span className='line'></span>
        )}
      </div>
      <div className='text'>
        <div className='title'>{props.title}</div>
        <div className='location'>
          <img
            className='icon'
            src={
              props.color === 'blue' ? locationIconPurple : locationIconOrange
            }
            alt='location'
          />
          {props.location}
        </div>
        <div className='sub-title margin-bottom-30'>{props.description}</div>
      </div>
    </div>
  );
};

export default TimelineItem;
