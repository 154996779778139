import React, { useState } from 'react';

import HomeAgenda from './components/HomeAgenda/HomeAgenda';
import Presentation from './components/Presentation/Presentation';

import '../../assets/styles/Common.scss';
import '../../assets/styles/Header.scss';
import './Home.scss';

import Experience from './components/Experience/Experience';

import TrackVisibility from 'react-on-screen';
import Education from './components/Education/Education';
import PersonalProjects from './components/PersonalProjects/PersonalProjects';
import Contact from './components/Contact/Contact';
import Skills from './components/Skills/Skills';

const Home = ({ handleNavigation, handleClickAgenda }) => {
  const [section, setSection] = useState('');
  const handleAgenda = (section) => {
    setSection(section);
  };

  return (
    <div>
      <div className='content'>
        <HomeAgenda handleClickAgenda={handleClickAgenda} section={section} />
        <div className='sections margin-top-100'>
          <TrackVisibility partialVisibility offset={-600}>
            <Presentation
              handleClickAgenda={handleClickAgenda}
              handleAgenda={handleAgenda}
            />
          </TrackVisibility>
          <TrackVisibility partialVisibility offset={-600}>
            <Experience handleAgenda={handleAgenda} />
          </TrackVisibility>
          <TrackVisibility partialVisibility offset={-600}>
            <Skills handleAgenda={handleAgenda} />
          </TrackVisibility>
          <TrackVisibility partialVisibility offset={-600}>
            <Education handleAgenda={handleAgenda} />
          </TrackVisibility>
          <TrackVisibility partialVisibility offset={-400}>
            <PersonalProjects handleAgenda={handleAgenda} />
          </TrackVisibility>

          <TrackVisibility partialVisibility offset={-400}>
            <Contact handleAgenda={handleAgenda} />
          </TrackVisibility>
        </div>
      </div>
    </div>
  );
};

export default Home;
