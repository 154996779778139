import React from 'react';
import './Menu.scss';
import ReactTooltip from 'react-tooltip';

const Menu = ({ handleClickAgenda }) => {
  return (
    <>
      <div>
        <ul className='list'>
          <li
            className='item selected'
            onClick={() => {
              handleClickAgenda('presentation');
            }}>
            Home
          </li>
          <li data-tip data-for='in-progress' className='item item-disabled'>
            Projects
          </li>

          <li data-tip data-for='in-progress' className='item item-disabled'>
            Blog
          </li>
          <li data-tip data-for='in-progress' className='item item-disabled'>
            Services
          </li>
          {/* <li data-tip data-for='in-progress' className='item item-disabled'>
            Contact
          </li> */}
          <ReactTooltip
            id='in-progress'
            type='warning'
            effect='solid'
            place='bottom'>
            <span>Under construction</span>
          </ReactTooltip>
        </ul>
      </div>
    </>
  );
};

export default Menu;
