import React, { useEffect } from 'react';
import './Contact.scss';

import contactIllustration from '../../../../assets/images/illustrations/contact-mail-illustration.svg';
import instagram from '../../../../assets/images/icons/instagram.png';
import github from '../../../../assets/images/icons/github.png';
import linkedin from '../../../../assets/images/icons/linkedin.png';
import mail from '../../../../assets/images/mail.png';
import { usePrevious } from '../../../../hooks/usePrevious';

const Contact = ({ isVisible, handleAgenda }) => {
  const prevIsActive = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevIsActive !== isVisible) {
      handleAgenda('contact');
    }
  }, [isVisible, handleAgenda, prevIsActive]);

  return (
    <div id='contact' className='section contact-section'>
      <div className='contact'>
        <div className='title'>Brewing a project in mind?</div>
        <div className='title'>Let's talk</div>
        <div className='description'>
          Feel free to email me for any further information and I will get back
          to you as soon as possible
        </div>
        {/* <div className='button button-large margin-top-30'>Let's connect</div> */}
        <div className='email-contact'>
          <div className='email-icon'>
            <img src={mail} alt='mail' />
          </div>
          <div className='email-info'>contact@vladmorosanu.com</div>
        </div>
        <div className='description follow-me margin-top-50'>
          Follow me
          <div className='icons'>
            <a href='https://github.com/vladmorosanu97'>
              <img
                className='icon margin-left-0'
                src={github}
                alt='github account'
              />
            </a>
            <a href='https://www.instagram.com/vladmorosanu97'>
              <img className='icon' src={instagram} alt='instagram account' />
            </a>
            <a href='https://www.linkedin.com/in/vlad-morosanu-987270149'>
              <img className='icon' src={linkedin} alt='linkedin account' />
            </a>
          </div>
        </div>
      </div>
      <div className='contact-image'>
        <img className='image' src={contactIllustration} alt='mail' />
      </div>
    </div>
  );
};

export default Contact;
