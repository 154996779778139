import React, { useEffect } from 'react';
import './Experience.scss';
import '../../../../assets/styles/FlexBox.scss';
import '../../../../assets/styles/Margins.scss';
import TimelineItem from '../TimelineItem/TimelineItem';

import { usePrevious } from '../../../../hooks/usePrevious';

const Experience = ({ isVisible, handleAgenda }) => {
  const prevIsActive = usePrevious(isVisible);
  useEffect(() => {
    if (isVisible === true && prevIsActive !== isVisible) {
      handleAgenda('experience');
    }
  }, [isVisible, handleAgenda, prevIsActive]);
  return (
    <div id='experience' className='section experience-section'>
      <div className='history'>
        <h1 className='title'>Experience</h1>

        <TimelineItem
          startDate={'10/2020'}
          endDate={'present'}
          title={'Full Stack Developer at Fortech'}
          location={'Iasi, Romania'}
          color={'blue'}
          description={
            'Built complex applications using .NET Framework, worked with MSMQ. Good knowledge of SQL Server, SQL jobs, queries and stored procedures.'
          }
        />

        <TimelineItem
          startDate={'07/2018'}
          endDate={'09/2020'}
          title={'Full Stack Developer at SABS Network'}
          location={'Iasi, Romania'}
          color={'blue'}
          lastItem={true}
          description={
            'Built applications using architectures such as NTier, Clean Architecture and MVC. Developed complex Web API applications using CQRS and CQS patterns'
          }
        />
      </div>
      <div className='details'>
        <div className='title'>Description</div>
        <div className='description'>
          My background consists of two years of programming experience, during
          which I improved essential skills in building web applications, having
          mainly used NET/.NET Core, Angular and React. As I’m committed to my
          personal development, I’m eager to also learn machine learning and
          artificial intelligence-based software development.
        </div>
        <div className='description margin-top-50'>
          During my first job as a junior web developer, I gained valuable
          experience working on frontend applications built on Angular and
          ReactJS. Alongside these technologies, I was able to implement key
          concepts that are widely used in software engineering, such as design
          patterns and clean code principles.
        </div>
        <div className='description margin-top-50'>
          Currently, at my new job, I am working with complex applications that
          provide financial services. Throughout the building process, I use
          technologies like .Net Framework, MSMQ, SQL jobs and Razor pages.
        </div>
      </div>
    </div>
  );
};

export default Experience;
